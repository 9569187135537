#root {
  height: 100vh;
}

.main-header .navbar-nav .nav-item .nav-link {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

